<template>
	<div class="grid p-fluid formgrid">
		<template v-if="loader==true">
			<!-- <div class="col-12 lg:col-12 flex justify-content-center my-2">
				<i class="pi pi-spin pi-spinner justify-content-center" style="font-size: 3rem"></i>
			</div> -->
			<div class="col-12 mb-2 lg:col-12">
			<ProgressBar mode="indeterminate" style="height: .5em" />
			</div>
		</template>
		<template v-if="select_proveedor==null">
			<div class="col-12 md:col-6 lg:col-6">
				<h2>CARTERA</h2>
				<Dropdown v-model="select_proveedor" :onChange="customer_selected" :disabled="load_indicadores" :showClear="true" :options="customers_group" optionLabel="nombre_empresa" placeholder="Seleccione una Empresa" class="mr-1" />
			</div>
			<div class="col-12 lg:col-12 mt-4">
			<DataTable ref="pagination" :value="customers_group_cartera" v-model:selection="selectedProducts" dataKey="id" :paginator="false" :rows="3" @paginate="onChangePage"
							:paginatorTemplate="'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'"
							:currentPageReportTemplate="'Showing '+pagination.from+' to '+pagination.to+' of '+pagination.total+' customers'" responsiveLayout="scroll">
					<Column field="sucursal_origina_descripcion" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							{{'# '+slotProps.data.document}}<br><strong>{{slotProps.data.name}}</strong>
						</template>
					</Column>
					<Column field="sucursal_origina_descripcion" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">generador</span>
							{{'# '+slotProps.data.documento_generador}}<br><strong>{{slotProps.data.razon_social}}</strong>
						</template>
					</Column>
					<Column field="venta_fecha" :sortable="false" class="">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							<strong>SIN VENCER</strong><br>
							<Badge :value="'$'+new Intl.NumberFormat().format(slotProps.data.indicadores.modulo_sin_vencer.total_valor)" severity="successu" size="large" class="mt-1 purple-bgcolor cursor-pointer" @click="showvalor(slotProps.data,1)"></Badge>
						</template>
					</Column>
					<Column field="venta_fecha" :sortable="false" class="">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							<strong>0 - 30 DIAS</strong><br>
							<Badge :value="'$'+new Intl.NumberFormat().format(slotProps.data.indicadores.modulo_0a30.total_valor)" severity="info" size="large" class="mt-1 cursor-pointer" @click="showvalor(slotProps.data,2)"></Badge>
						</template>
					</Column>
					<Column field="venta_fecha" :sortable="false" class="">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							<strong>31 - 60 DIAS</strong><br>
							<Badge :value="'$'+new Intl.NumberFormat().format(slotProps.data.indicadores.modulo_31a60.total_valor)" severity="success" size="large" class="mt-1 cursor-pointer" @click="showvalor(slotProps.data,3)"></Badge>
						</template>
					</Column>
					<Column field="venta_fecha" :sortable="false" class="">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							<strong>61 - 90 DIAS</strong><br>
							<Badge :value="'$'+new Intl.NumberFormat().format(slotProps.data.indicadores.modulo_61a90.total_valor)" severity="warning" size="large" class="mt-1 cursor-pointer" @click="showvalor(slotProps.data,4)"></Badge>
						</template>
					</Column>
					<Column field="venta_fecha" :sortable="false" class="">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							<strong>MAYOR A 90 DIAS</strong><br>
							<Badge :value="'$'+new Intl.NumberFormat().format(slotProps.data.indicadores.modulo_mayor_90.total_valor)" severity="dangeru" size="large" class="mt-1 pink-bgcolor cursor-pointer" @click="showvalor(slotProps.data,5)"></Badge>
						</template>
					</Column>
					<template #paginatorstart>
						<!-- <Button type="button" icon="pi pi-refresh" /> -->
						<!-- <pagination v-model="pagination.current_page" ref="pagination" :records="pagination.total" :per-page="pagination.per_page" @paginate="onChangePage" style="background-color: aqua;"/> -->
					</template>
					<template #paginatorend>
						<!-- <Button type="button" icon="pi pi-cloud" /> -->
					</template>
				</DataTable>
			</div>
		</template>
		<template v-else>
		<div class="col-12 md:col-6 lg:col-2">
			<h5>{{select_proveedor.nombre_empresa}}</h5>
			<Dropdown v-model="select_proveedor" :showClear="true" :disabled="load_indicadores" :options="customers_group" optionLabel="nombre_empresa" placeholder="Seleccione un Campo" :onChange="customer_selected" />
			<Button v-show="detalle_cartera.length >= 1" icon="pi pi-undo" class="p-button-raised mt-1" label="Limpiar tabla" @click="cleartable"></Button>
		</div>
		
		<div class="col-12 md:col-6 lg:col-2">
			<div class="card overview-box flex flex-column pt-2 purple-bgcolor solid-surface-text-color cursor-pointer" @click="selectindicador(1)">
				<div class="flex align-items-center">
					<i class="pi pi-wallet"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">SIN VENCER</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text solid-surface-text-color"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 80px;">
						<span class="mb-1 fs-large">{{sin_vencer}} - <br>${{sin_vencer_valor}}</span>
						<!-- <span class="overview-status p-1 fs-small">85 Responded</span> -->
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData8" type="line" :data="overviewChartData8" :options="overviewChartOptions2" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-6 lg:col-2">
			<div class="card overview-box flex flex-column pt-2 blue-bgcolor solid-surface-text-color cursor-pointer" @click="selectindicador(2)">
				<div class="flex align-items-center">
					<i class="pi pi-wallet"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">0 - 30 DIAS</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text solid-surface-text-color"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 80px;">
						<span class="mb-1 fs-large">{{cuantos_0a30}} - <br>${{cuantos_0a30_valor}}</span>
						<!-- <span class="overview-status p-1 fs-small">85 Responded</span> -->
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData8" type="line" :data="overviewChartData8" :options="overviewChartOptions2" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-6 lg:col-2">
			<div class="card overview-box flex flex-column pt-2 green-bgcolor solid-surface-text-color cursor-pointer" @click="selectindicador(3)">
				<div class="flex align-items-center">
					<i class="pi pi-wallet"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">31 - 60 DIAS</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text solid-surface-text-color"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 80px;">
						<span class="mb-1 fs-large">{{cuantos_31a60}} - <br>${{cuantos_31a60_valor}}</span>
						<!-- <span class="overview-status p-1 fs-small">1420 Completed</span> -->
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData5" type="line" :data="overviewChartData5" :options="overviewChartOptions2" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-6 lg:col-2">
			<div class="card overview-box flex flex-column pt-2 orange-bgcolor solid-surface-text-color cursor-pointer" @click="selectindicador(4)">
				<div class="flex align-items-center">
					<i class="pi pi-wallet"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">61 - 90 DIAS</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text solid-surface-text-color"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 80px;">
						<span class="mb-1 fs-large">{{cuantos_61a90}} - <br>${{cuantos_61a90_valor}}</span>
						<!-- <span class="overview-status p-1 fs-small">$9,640 Income</span> -->
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData6" type="line" :data="overviewChartData6" :options="overviewChartOptions2" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>

		<div class="col-12 md:col-6 lg:col-2">
			<div class="card overview-box flex flex-column pt-2 pink-bgcolor solid-surface-text-color cursor-pointer" @click="selectindicador(5)">
				<div class="flex align-items-center">
					<i class="pi pi-wallet"></i>
					<h6 class="m-0" :class="{'pl-2': !isRTL, 'pr-2': isRTL}">MAYOR A 90 DIAS</h6>
					<div :class="{'ml-auto': !isRTL, 'mr-auto': isRTL}">
						<Button icon="pi pi-ellipsis-h" class="p-button-rounded p-button-text solid-surface-text-color"></Button>
						<Menu ref="menu" :popup="true" :model="items"></Menu>
					</div>
				</div>
				<div class="flex justify-content-between mt-3 flex-wrap">
					<div class="flex flex-column" style="width: 80px;">
						<span class="mb-1 fs-large">{{cuantos_mas_90}} - <br>${{cuantos_mas_90_valor}}</span>
						<!-- <span class="overview-status p-1 fs-small">25402 Registered</span> -->
					</div>
					<div class="flex align-items-end">
						<!-- <Chart ref="overviewChartData7" type="line" :data="overviewChartData7" :options="overviewChartOptions2" responsive="true" :height="60" :width="160"></Chart> -->
					</div>
				</div>
			</div>
		</div>
		
		<div class="col-3 lg:col-3 mt-4" v-show="detalle_cartera.length >= 1">
            <Dropdown v-model="campo_selected" :showClear="true" :options="campos_tabla" optionLabel="name" optionValue="code" placeholder="Seleccione un Campo" class="mr-2" />
		</div>
		<template v-if="campo_selected=='num_factura'">
			<div class="col-3 lg:col-3 mt-4">
				
				<InputText placeholder="Núm Factura" v-model="text_filter" required="true" autofocus type="number" />
			</div>
		</template>
			<template v-if="campo_selected=='fecha_venta' || campo_selected=='fecha_vencimiento'">
				
				
					<template v-if="frmfiltros.rangofechasolicitud">
						<div class="col-3 lg:col-3">
							<Checkbox id="checkrangofechasolicitud" :binary="true" v-model="frmfiltros.rangofechasolicitud" class="mb-1 mr-1 ml-5"></Checkbox>
							<label for="checkrangofechasolicitud">Rango</label>
							<Calendar v-model="text_filter" :showTime="false" :showIcon="true" :locale="es" class="ml-2" placeholder="Seleccione fecha inicial" />
						</div>
						<div class="col-3 lg:col-3 mt-4">
							<Calendar v-model="frmfiltros.fechasolicitudrango" :minDate="text_filter" :showTime="false" :showIcon="true" :locale="es" class="ml-3" placeholder="Seleccione fecha final"/>
						</div>
					</template>
					<template v-else>
						<div class="col-3 lg:col-3">
							<Checkbox id="checkrangofechasolicitud" :binary="true" v-model="frmfiltros.rangofechasolicitud" class="mb-1 mr-1 ml-5"></Checkbox>
							<label for="checkrangofechasolicitud">Rango</label>
							<Calendar v-model="text_filter" :showTime="false" :showIcon="true" :locale="es" class="ml-2" placeholder="Seleccione fecha"/>
						</div>
					</template>
			</template>
		<template v-if="campo_selected!=null">
			<div class="col-3 lg:col-3">
				<div class="grid mt-4">
					<div class="p-fluid col-6">
						<Button type="button" class="p-button-raised mb-1" label="Buscar" icon="pi pi-search" :loading="loading[0]" @click="load(0,1)" />
					</div>
					<div class="p-fluid col-6">
						<Button type="button" class="p-button-raised" label="Limpiar" icon="pi pi-filter-slash" :loading="loading[0]" @click="limpiar" />
					</div>
				</div>
			</div>
		</template>
		<div class="col-12 lg:col-12" v-show="detalle_cartera.length >= 1">
			<div class="col-3 lg:col-3">
			<p class="valor_facturas">{{valor_seleccionado_facturas}}</p>
			<Button icon="pi pi-arrow-left" label="Pagar en Linea" @click="open_detalle_select_facturas" class="p-button-warning mr-2"  />
			<!-- <Button icon="pi pi-wallet" label="Pagar en Linea" :loading="loader" class="p-button-rounded" @click="open_modal_pagar(slotProps.data)" /> -->
		</div>
		<Sidebar v-model:visible="visibleRight" :baseZIndex="1000" position="right" class="p-sidebar-lg">
			<div class="text-center">
				<h3 style="font-weight: normal">Detalle del Pago</h3>
				<!-- <p class="valor_facturas">{{valor_cosolidado_facturas()}}</p> -->
				<p class="valor_facturas">{{valor_seleccionado_facturas}}</p>
				<template v-if="facturas_seleccionadas.length > 0">
					
					<template v-if="pago_parcial_actualizado == true">
						<div>
							<Button icon="pi pi-wallet" label="Pagar en Linea" :loading="loader" class="p-button-rounded" @click="open_modal_pagar()" />
						</div>
					</template>
					<template v-else>
						<div>
							<Button icon="pi pi-refresh" label="Actualizar saldo" :loading="loader" class="p-button-rounded p-button-warning" @click="refreshSaldo()" />
						</div>
					</template>
				</template>
			</div>
			
			<!-- <p><strong>Facturas: </strong>{{selectedProducts.length || 0}}</p> -->
			<p><strong>Pago parcial:</strong></p>
			<Checkbox id="checkrangofechasolicitud" :binary="true" v-model="pago_parcial" class="mb-1 mr-1 ml-5"></Checkbox>
			<DataTable :value="facturas_seleccionadas" let-i="rowIndex" v-model="facturas_seleccionadas" selectionMode="single" :paginator="true" :rows="5" responsiveLayout="scroll" class="mt-1">
				<Column field="venta_numero" header="Parcial" :sortable="false">
					<template #body="slotProps">
						<Checkbox id="checkrangofechasolicitud" :binary="true" v-model="select_parcial[slotProps.index]" class="mb-3 mr-3" @click="show_parcial(slotProps.data,slotProps.index)"></Checkbox>
					</template>
				</Column>
				<Column field="venta_numero" header="Número" :sortable="false">
					<template #body="slotProps">
						{{slotProps.data.venta_numero}}
					</template>
				</Column>
				<Column field="dias_cartera" header="Días Cartera" :sortable="false">
					<template #body="slotProps">
						<span class="p-column-title">Dias Cartera</span>
						{{slotProps.data.dias_cartera}} días
					</template>
				</Column>
				<Column field="dias_vencido" header="Días Vencido" :sortable="false">
					<template #body="slotProps">
						<span class="p-column-title">Dias Vencido</span>
						<template v-if="slotProps.data.dias_vencido < 0">
							0
						</template>
						<template v-else>
						{{slotProps.data.dias_vencido}} días
						</template>
					</template>
				</Column>
				<Column field="venta_saldo" header="Valor Saldo" :sortable="false">
					<template #body="slotProps">
						<span class="p-column-title">Valor Saldo</span>
						<!--  -->
						<template v-if="pago_parcial == false">
							<template v-if="select_parcial[slotProps.index] == true">
								<InputNumber :class="{'p-invalid': submitted && !slotProps.data.saldo, 'mb-3 mt-1': true}" placeholder="Valor a Pagar" 
								:modelValue="slotProps.data.saldo" inputId="horizontal" v-model="slotProps.data.saldo"
								 buttonLayout="horizontal" :step="10000"
								 :max="parseInt(slotProps.data.venta_total)-parseInt(slotProps.data.venta_abonos)"
								  @input="show_nuevo_valor($event.value,slotProps.index,slotProps.data)"
							/>
							</template>
							<template v-else>
								${{new Intl.NumberFormat().format(slotProps.data.saldo)}}
							</template>
						</template>
						<template v-else>
							<!-- {{parseInt(slotProps.data.valor_a_pagar)}} -->
							<InputNumber :class="{'p-invalid': submitted && !slotProps.data.saldo, 'mb-3 mt-1': true}" placeholder="Valor a Pagar" 
								:modelValue="slotProps.data.saldo" inputId="horizontal" v-model="slotProps.data.saldo"
								 buttonLayout="horizontal" :step="10000"
								 :max="parseInt(slotProps.data.venta_total)-parseInt(slotProps.data.venta_abonos)"
								  @input="show_nuevo_valor($event.value,slotProps.index,slotProps.data)"
							/>
						</template>
								 <!-- :max="parseInt(slotProps.data.valor_a_pagar)" -->
					</template>
				</Column>
				<Column field="acciones" header="Acciones">
					<template #body="slotProps">
						<!-- <Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editProduct(slotProps.data)" /> -->
						<!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="confirmDeleteProduct(slotProps.data,slotProps.index)" /> -->
						
						<Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="confirmDeleteProduct(slotProps.data,slotProps.index)" />
					</template>
				</Column>
			</DataTable>
		</Sidebar>
		
		<DataTable ref="pagination" :value="detalle_cartera" v-model:selection="selectedProducts" dataKey="id" :paginator="false" :rows="3" @paginate="onChangePage" @row-select-all="valor_facturas_todos" @row-unselect-all="quitar_valor_facturas_todos"
							:paginatorTemplate="'CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown'" @row-select="valor_facturas" @row-unselect="valor_facturas"
							:currentPageReportTemplate="'Showing '+pagination.from+' to '+pagination.to+' of '+pagination.total+' customers'" responsiveLayout="scroll">
					<template #header>
					</template>
					<!-- <Column selectionMode="multiple" headerStyle="width: 3rem"></Column> -->
					<Column field="select_factura" selectionMode="multiple" headerStyle="width: 3rem"></Column>
					<!-- <Column field="sucursal_origina_descripcion" header="Sucursal" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Sucursal</span>
							{{slotProps.data.sucursal_origina_descripcion}}
						</template>
					</Column> -->
					<Column field="venta_numero" header="Nro. Factura" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Nro. Factura</span>
							{{slotProps.data.venta_numero}}
						</template>
					</Column>
					<Column field="venta_fecha" header="Fecha Factura" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							{{slotProps.data.venta_fecha}}
						</template>
					</Column>
					<!-- <Column field="venta_fecha" header="Fecha Elaboración" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Fecha</span>
							{{slotProps.data.venta_fechael}}
						</template>
					</Column> -->
					<Column field="venta_vence" header="Fecha Vence" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Vence</span>
							{{slotProps.data.venta_vence}}
						</template>
					</Column>
					<Column field="venta_plazo" header="Plazo" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Plazo</span>
							{{slotProps.data.venta_plazo}}
						</template>
					</Column>
					<Column field="dias_cartera" header="Días Cartera" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Dias Cartera</span>
							{{slotProps.data.dias_cartera}} días
						</template>
					</Column>
					<Column field="dias_vencido" header="Días Vencido" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Dias Vencido</span>
							<template v-if="slotProps.data.dias_vencido < 0">
								0
							</template>
							<template v-else>
							{{slotProps.data.dias_vencido}} días
							</template>
						</template>
					</Column>
					<Column field="venta_total" header="Valor Factura" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Valor Factura</span>
							${{new Intl.NumberFormat().format(slotProps.data.venta_total)}}
							<!-- {{slotProps.data.venta_total}} -->
						</template>
					</Column>
					<Column field="venta_abonos" header="Valor Abonado" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Valor Abonado</span>
							${{new Intl.NumberFormat().format(slotProps.data.venta_abonos)}}
						</template>
					</Column>
					<Column field="venta_saldo" header="Valor Saldo" :sortable="false">
						<template #body="slotProps">
							<span class="p-column-title">Valor Saldo</span>
							<!-- ${{new Intl.NumberFormat().format(slotProps.data.venta_saldo)}} -->
							${{calcular_saldo(slotProps.data.venta_total,slotProps.data.venta_abonos)}}
						</template>
					</Column>
					<template #paginatorstart>
						<!-- <Button type="button" icon="pi pi-refresh" /> -->
						<!-- <pagination v-model="pagination.current_page" ref="pagination" :records="pagination.total" :per-page="pagination.per_page" @paginate="onChangePage" style="background-color: aqua;"/> -->
					</template>
					<template #paginatorend>
						<!-- <Button type="button" icon="pi pi-cloud" /> -->
					</template>
				</DataTable>
				<div>
					<Paginator :v-model:first="pagination.from" :rows="pagination.per_page" :rowsPerPageOptions="[10]" :totalRecords="pagination.total" @page="onChangePage($event)"></Paginator>
				</div>
				
		</div>
		</template>
		<Dialog v-model:visible="modal_pagos" style="width: 900px;z-index: 1000;" :modal="true" header="Pago de Facturas" class="p-fluid modal-pago">
		
			
			<div class="field formgrid grid">
				<template v-if="select_medio_pago == 0">
					<div class="col-12 text-center">
					<h5>Seleccione su medio de pago</h5>
					
						<div class="grid flex">
							<div class="col-4 mt-2 mb-2 py-2 text-center cursor-pointer container-precios" @click="select_medio_pago = 1">
								<img id="pse" src="layout/images/BotonPSE.png" alt="ultima-layout" style="height: 8rem">
								<p><strong>PSE</strong></p>
							</div>
							<div class="col-4 mt-2 mb-2  py-2 text-center cursor-pointer container-precios" @click="select_medio_pago = 2">
								<img id="pse" src="layout/images/tarjetas-credito.png" alt="ultima-layout" style="height: 8rem">
								<p><strong>Tarjeta de credito</strong></p>
							</div>
							<div class="col-4 mt-2 mb-2  py-2 text-center cursor-pointer container-precios" @click="select_medio_pago = 3">
								<img id="pse" src="layout/images/comprobante-pago.png" alt="ultima-layout" style="height: 8rem">
								<p><strong>Comprobante de Pago</strong></p>
							</div>
						</div>
						
					</div>
					
				</template>
				<template v-if="select_medio_pago == 1">
					<template v-if="select_bank">
						<div class="col-6">
							
							<Panel header="Información Bancaria">
								<div class="flex align-items-center justify-content-between pt-2 py-2">
									<span>Quien Paga</span>
									<span class="font-bold">{{frm_bancario.name}}</span>
								</div>
								<div class="flex align-items-center justify-content-between py-2">
									<span>Banco</span>
									<span class="font-bold">{{select_bank.bankName}}</span>
								</div>
								<div class="flex align-items-center justify-content-between pt-2 py-2">
									<span>Valor a Pagar</span>
									<!-- <span class="font-bold">${{new Intl.NumberFormat().format(frm_bancario.valor_pagar)}}</span> -->
									<span class="font-bold">${{frm_bancario.valor_pagar}}</span>
								</div>
								<div class="flex align-items-center justify-content-between py-2">
									<span>Tipo Documento</span>
									<span class="font-bold">{{frm_bancario.selectipo_documento}}</span>
								</div>
								<div class="flex align-items-center justify-content-between py-2">
									<span>Número Documento</span>
									<span class="font-bold">{{frm_bancario.documento}}</span>
								</div>
								<div class="flex align-items-center justify-content-between pt-2 py-2">
									<span>Descripción</span>
									<span class="font-bold">{{frm_bancario.descripcion}}</span>
								</div>
								<div class="flex align-items-center justify-content-between pt-2 py-2">
									<span>Email</span>
									<span class="font-bold">{{frm_bancario.email}}</span>
								</div>
								<div class="flex align-items-center justify-content-between pt-2 py-2">
									<span>Celular</span>
									<span class="font-bold">{{frm_bancario.cell_phone}}</span>
								</div>
							</Panel>
							<h5>Abonos:</h5>
							<Accordion>
								<template v-for="(grup_empr,index) in abonos" :key="index">
								<AccordionTab>
									<template v-slot:header>
										<div class="flex align-items-center">
											<i class="pi pi-bookmark fs-xlarge mr-3 pink-color"></i>
											<div class="flex flex-column">
												<h5 class="mb-0">{{grup_empr.venta_prefijo}}-{{grup_empr.venta_numero}}</h5>
												<p>${{new Intl.NumberFormat().format(grup_empr.saldo)}}</p>
											</div>
										</div>
									</template>
									
									<ul class="list-none p-0 m-0">
										<template v-for="(abono,index_abonos) in grup_empr.abonos" :key="index_abonos">
										<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
											<div class="text-500 w-6 md:w-2 font-medium">Abono</div>
											<div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">${{new Intl.NumberFormat().format(abono.valor)}}</div>
											<div class="w-6 md:w-3 flex justify-content-end">{{abono.descripcion}}</div>
										</li>
										</template>
									</ul>
								
								</AccordionTab>
								</template>
							</Accordion>
						</div>
					</template>
					<div class="col-6 mt-2 mb-2 px-4">
						<label for="banco">Selecciona un banco</label>
						<Dropdown v-model="select_bank" class="mt-2 mb-3" :filter="true" :onChange="bank_selected" :showClear="true" :options="list_banks" optionLabel="bankName" optionValue="bankCode"  placeholder="Seleccione un Banco" />
						<template v-if="select_bank">

							

							<label for="card_number">Ingrese Tipo de Documento</label>
							<Dropdown v-model="frm_bancario.selectipo_documento" :options="tipos_documentos" placeholder="Seleccionar" optionLabel="name" optionValue="code" :showClear="true" :class="[{'p-invalid': submitted && frm_bancario.selectipo_documento==null, 'mb-3': true}]"/>

							<label for="card_number" class="mt-3">Ingrese Número de Documento</label>
							<template v-if="frm_bancario.selectipo_documento=='NIT'">
								<template class="mt-1 mb-3">
									<InputMask mask="999.999.999" id="nit" placeholder="NIT de la empresa*" class="w-full" v-model="frm_bancario.documento" :class="{'p-invalid': submitted && !frm_bancario.documento, 'mb-3': true}"/>
								</template>
							</template>
							<template v-else>
								<template class="mt-1 mb-3">
									<InputNumber mode="decimal" :useGrouping="false" id="cedula" placeholder="Número de documento" class="w-full" v-model="frm_bancario.documento" :class="{'p-invalid': submitted && !frm_bancario.documento, 'mb-3': true}"/>
								</template>
							</template>

							<label for="card_number">A nombre de quien paga</label>
							<InputText class="mt-1 mb-3" placeholder="Ingrese razón social" v-model="frm_bancario.name" required="true" :class="{'p-invalid': submitted && !frm_bancario.name}" autofocus />

							<label for="card_number">Email</label>
							<InputText class="mt-1 mb-3" placeholder="Ingrese email" id="email" v-model="frm_bancario.email" required="true" :class="{'p-invalid': submitted && !frm_bancario.email}" autofocus />

							<label for="card_number">Ingrese Valor a Pagar</label>
							<InputNumber class="mt-1 mb-3" placeholder="Valor a Pagar" inputId="horizontal" v-model="frm_bancario.valor_pagar" :max="info_factura.venta_total" showButtons buttonLayout="horizontal" :step="10000"
                    		decrementButtonClass="p-button-danger" incrementButtonClass="p-button-success" incrementButtonIcon="pi pi-plus" decrementButtonIcon="pi pi-minus" mode="currency" currency="COP" />

							<label for="card_number">Ingrese Descripción del pago</label>
							<Textarea placeholder="Descripción del pago.." :class="{'p-invalid': submitted && !frm_bancario.descripcion, 'mb-3 mt-1': true}" v-model="frm_bancario.descripcion" rows="5" cols="30" />

							<label for="card_number">Ingrese Número de Celular</label>
							<InputNumber mode="decimal" :useGrouping="false" id="cedula" placeholder="Número de celular" class="w-full" v-model="frm_bancario.cell_phone" :class="{'p-invalid': submitted && !frm_bancario.cell_phone, 'mb-3': true}"/>
						</template>
					</div>
				</template>

				<template v-if="select_medio_pago == 2">
					<!-- <template v-if="select_bank"> -->
					<div class="col-6">
						<Panel header="Información Bancaria">
							<div class="flex align-items-center justify-content-between pt-2 py-2">
								<span>Quien Paga</span>
								<span class="font-bold">{{frm_bancario.name}}</span>
							</div>
							<!-- <div class="flex align-items-center justify-content-between pt-2 py-2">
									<span>Factura</span>
									<span class="font-bold">{{info_factura.venta_prefijo}}-{{info_factura.venta_numero}}</span>
								</div> -->
							<div class="flex align-items-center justify-content-between pt-2 py-2">
								<span>Núm Tarjeta</span>
								<span class="font-bold">{{frm_bancario.card_number}}</span>
							</div>
							<div class="flex align-items-center justify-content-between pt-2 py-2">
								<span>Fecha Expiración</span>
								<span class="font-bold">{{frm_bancario.card_fecha}}</span>
							</div>
							<div class="flex align-items-center justify-content-between pt-2 py-2">
								<span>Codigo cvc</span>
								<span class="font-bold">
									<template v-for="(col,index) in frm_bancario.card_cvc.length" :key="index">
										*
									</template>
								</span>
							</div>
							<!-- <div class="flex align-items-center justify-content-between pt-2 py-2">
								<span>Valor Factura</span>
								<span class="font-bold">${{new Intl.NumberFormat().format(info_factura.venta_total)}}</span>
							</div> -->
							<div class="flex align-items-center justify-content-between pt-2 py-2">
								<span>Valor a Pagar</span>
								<!-- <span class="font-bold">${{new Intl.NumberFormat().format(frm_bancario.valor_pagar)}}</span> -->
								<span class="font-bold">${{frm_bancario.valor_pagar}}</span>
							</div>
							<div class="flex align-items-center justify-content-between py-2">
								<span>Tipo Documento</span>
								<span class="font-bold">{{frm_bancario.selectipo_documento}}</span>
							</div>
							<div class="flex align-items-center justify-content-between py-2">
								<span>Número Documento</span>
								<span class="font-bold">{{frm_bancario.documento}}</span>
							</div>
							<div class="flex align-items-center justify-content-between pt-2 py-2">
								<span>Descripción</span>
								<span class="font-bold">{{frm_bancario.descripcion}}</span>
							</div>
							<div class="flex align-items-center justify-content-between pt-2 py-2">
								<span>Email</span>
								<span class="font-bold">{{frm_bancario.email}}</span>
							</div>
							<div class="flex align-items-center justify-content-between pt-2 py-2">
								<span>Celular</span>
								<span class="font-bold">{{frm_bancario.cell_phone}}</span>
							</div>
						</Panel>
						<h5>Abonos:</h5>
						<Accordion>
							<template v-for="(grup_empr,index) in abonos" :key="index">
							<AccordionTab>
								<template v-slot:header>
									<div class="flex align-items-center">
										<i class="pi pi-bookmark fs-xlarge mr-3 pink-color"></i>
										<div class="flex flex-column">
											<h5 class="mb-0">{{grup_empr.venta_prefijo}}-{{grup_empr.venta_numero}}</h5>
											<p>${{new Intl.NumberFormat().format(grup_empr.saldo)}}</p>
										</div>
									</div>
								</template>
								
								<ul class="list-none p-0 m-0">
									<template v-for="(abono,index_abonos) in grup_empr.abonos" :key="index_abonos">
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-2 font-medium">Abono</div>
										<div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">${{new Intl.NumberFormat().format(abono.valor)}}</div>
										<div class="w-6 md:w-3 flex justify-content-end">{{abono.descripcion}}</div>
									</li>
									</template>
								</ul>
							
							</AccordionTab>
							</template>
						</Accordion>
						<!-- <DataTable :value="abonos" let-i="rowIndex" v-model="table_abonos" selectionMode="single" :paginator="true" :rows="5" responsiveLayout="scroll" class="mt-5">
							<Column field="descripcion" header="Descripción" :sortable="false">
								<template #body="slotProps">
									{{slotProps.data.descripcion}}
								</template>
							</Column>
							<Column field="valor" header="Valor" :sortable="false">
								<template #body="slotProps">
									${{new Intl.NumberFormat().format(slotProps.data.valor)}}
								</template>
							</Column>
							
						</DataTable> -->
					</div>
					<!-- </template> -->

					<div class="col-6 mt-2 mb-2 px-4">
							<label for="card_number">Ingrese núm tarjeta</label>
							<InputMask mask="9999 9999 9999 999?9" v-model="frm_bancario.card_number" :class="{'p-invalid': submitted && !frm_bancario.card_number, 'mb-3 mt-1': true}" placeholder="9999-9999-9999-9999" />
							<!-- <InputText v-model="frm_bancario.card_number" :class="{'p-invalid': submitted && !frm_bancario.card_number, 'mb-3 mt-1': true}" placeholder="9999-9999-9999-9999" /> -->


							<label for="card_number">Ingrese Fecha Expiración</label>
							<Calendar v-model="frm_bancario.card_fecha" :class="{'p-invalid': submitted && !frm_bancario.card_fecha, 'mb-3 mt-1': true}" @click="tipo_formato_fecha_card" :showTime="false" :showButtonBar="true"  view="month" :showIcon="true" dateFormat="mm/yy" :locale="es" placeholder="Seleccione Mes y Año"/>

							<label for="card_number">Ingrese Codigo cvc</label>
							<InputText class="mt-1 mb-3" placeholder="Ingrese" :class="{'p-invalid': submitted && !frm_bancario.card_cvc, 'mb-3 mt-1': true}" v-model="frm_bancario.card_cvc" required="true" autofocus type="number" />

							<label for="card_number">A nombre de quien paga</label>
							<InputText class="mt-1 mb-3" placeholder="Ingrese razón social" v-model="frm_bancario.name" required="true" :class="{'p-invalid': submitted && !frm_bancario.name}" autofocus />

							<label for="card_number">Email</label>
							<InputText class="mt-1 mb-3" placeholder="Ingrese email" id="email" v-model="frm_bancario.email" required="true" :class="{'p-invalid': submitted && !frm_bancario.email}" autofocus />

							<!-- <div> -->
								<label for="card_number">Ingrese Valor a Pagar</label><br>
								<p class="font-bold mt-1 mb-0" style="font-size: 1.5rem;">${{frm_bancario.valor_pagar}}</p><br>
							<!-- </div> -->
							
							
							
							
							<label for="card_number">Ingrese Descripción del pago</label>
							<Textarea placeholder="Descripción del pago.." :class="{'p-invalid': submitted && !frm_bancario.descripcion, 'mb-3 mt-1': true}" v-model="frm_bancario.descripcion" rows="5" cols="30" />

							<label for="card_number">Ingrese Número de Celular</label>
							<InputNumber mode="decimal" :useGrouping="false" id="celular" placeholder="Número de celular" class="w-full" v-model="frm_bancario.cell_phone" :class="{'p-invalid': submitted && !frm_bancario.cell_phone, 'mb-3': true}"/>

							<label for="card_number">Ingrese Tipo de Documento</label>
							<Dropdown v-model="frm_bancario.selectipo_documento" :options="tipos_documentos" placeholder="Seleccionar" optionLabel="name" optionValue="code" :showClear="true" :class="[{'p-invalid': submitted && frm_bancario.selectipo_documento==null, 'mb-3': true}]"/>

							<label for="card_number" class="mt-3">Ingrese Número de Documento</label>
							<template v-if="frm_bancario.selectipo_documento=='NIT'">
								<div class="mt-1 mb-3">
									<InputMask mask="999.999.999" id="nit" placeholder="NIT de la empresa*" class="w-full" v-model="frm_bancario.documento" :class="{'p-invalid': submitted && !frm_bancario.documento, 'mb-3': true}"/>
								</div>
							</template>
							<template v-else>
								<div class="mt-1 mb-3">
									<InputNumber mode="decimal" :useGrouping="false" id="cedula" :modelValue="frm_bancario.documento" placeholder="Número de documento" class="w-full" v-model="frm_bancario.documento" :class="{'p-invalid': submitted && !frm_bancario.documento, 'mb-3': true}"/>
								</div>
							</template>
						<!-- </template> -->
					</div>
				</template>

				<template v-if="select_medio_pago == 3">
					<!-- <template v-if="select_bank"> -->
					<div class="col-6">
						<Panel header="Información Bancaria">

							<div class="flex align-items-center justify-content-between pt-2 py-2">
								<span>Quien Paga</span>
								<span class="font-bold">{{frm_bancario.name}}</span>
							</div>
							<div class="flex align-items-center justify-content-between pt-2 py-2">
								<span>Valor a Pagar</span>
								<span class="font-bold">${{frm_bancario.valor_pagar}}</span>
							</div>
							<div class="flex align-items-center justify-content-between py-2">
								<span>Tipo Documento</span>
								<span class="font-bold">{{frm_bancario.selectipo_documento}}</span>
							</div>
							<div class="flex align-items-center justify-content-between py-2">
								<span>Número Documento</span>
								<span class="font-bold">{{frm_bancario.documento}}</span>
							</div>
							<div class="flex align-items-center justify-content-between pt-2 py-2">
								<span>Descripción</span>
								<span class="font-bold">{{frm_bancario.descripcion}}</span>
							</div>
							<div class="flex align-items-center justify-content-between pt-2 py-2">
								<span>Email</span>
								<span class="font-bold">{{frm_bancario.email}}</span>
							</div>
							<div class="flex align-items-center justify-content-between pt-2 py-2">
								<span>Celular</span>
								<span class="font-bold">{{frm_bancario.cell_phone}}</span>
							</div>
						</Panel>
						<h5>Abonos:</h5>
						<Accordion>
							<template v-for="(grup_empr,index) in abonos" :key="index">
							<AccordionTab>
								<template v-slot:header>
									<div class="flex align-items-center">
										<i class="pi pi-bookmark fs-xlarge mr-3 pink-color"></i>
										<div class="flex flex-column">
											<h5 class="mb-0">{{grup_empr.venta_prefijo}}-{{grup_empr.venta_numero}}</h5>
											<p>${{new Intl.NumberFormat().format(grup_empr.saldo)}}</p>
										</div>
									</div>
								</template>
								
								<ul class="list-none p-0 m-0">
									<template v-for="(abono,index_abonos) in grup_empr.abonos" :key="index_abonos">
									<li class="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap">
										<div class="text-500 w-6 md:w-2 font-medium">Abono</div>
										<div class="text-900 w-full md:w-7 md:flex-order-0 flex-order-1">${{new Intl.NumberFormat().format(abono.valor)}}</div>
										<div class="w-6 md:w-3 flex justify-content-end">{{abono.descripcion}}</div>
									</li>
									</template>
								</ul>
							
							</AccordionTab>
							</template>
						</Accordion>
					</div>

					<div class="col-6 mt-2 mb-2 px-4">

							<label for="card_number">A nombre de quien paga</label>
							<InputText class="mt-1 mb-3" placeholder="Ingrese razón social" v-model="frm_bancario.name" required="true" :class="{'p-invalid': submitted && !frm_bancario.name}" autofocus />

							<label for="card_number">Email</label>
							<InputText class="mt-1 mb-3" placeholder="Ingrese email" id="email" v-model="frm_bancario.email" required="true" :class="{'p-invalid': submitted && !frm_bancario.email}" autofocus />

							<!-- <div> -->
								<label for="card_number">Ingrese Valor a Pagar</label><br>
								<p class="font-bold mt-1 mb-0" style="font-size: 1.5rem;">${{frm_bancario.valor_pagar}}</p><br>
							<!-- </div> -->
							
							
							
							
							<label for="card_number">Ingrese Descripción del pago</label>
							<Textarea placeholder="Descripción del pago.." :class="{'p-invalid': submitted && !frm_bancario.descripcion, 'mb-3 mt-1': true}" v-model="frm_bancario.descripcion" rows="5" cols="30" />

							<label for="card_number">Ingrese Número de Celular</label>
							<InputNumber mode="decimal" :useGrouping="false" id="celular" placeholder="Número de celular" :modelValue="frm_bancario.cell_phone" class="w-full" v-model="frm_bancario.cell_phone" :class="{'p-invalid': submitted && !frm_bancario.cell_phone, 'mb-3': true}"/>

							<label for="card_number">Ingrese Tipo de Documento</label>
							<Dropdown v-model="frm_bancario.selectipo_documento" :options="tipos_documentos" placeholder="Seleccionar" optionLabel="name" optionValue="code" :showClear="true" :class="[{'p-invalid': submitted && frm_bancario.selectipo_documento==null, 'mb-3': true}]"/>

							<label for="card_number" class="mt-3">Ingrese Número de Documento</label>
							<template v-if="frm_bancario.selectipo_documento=='NIT'">
								<div class="mt-1 mb-3">
									<InputMask mask="999.999.999" id="nit" placeholder="NIT de la empresa*" class="w-full" :modelValue="frm_bancario.documento" v-model="frm_bancario.documento" :class="{'p-invalid': submitted && !frm_bancario.documento, 'mb-3': true}"/>
								</div>
							</template>
							<template v-else>
								<div class="mt-1 mb-3">
									<InputNumber mode="decimal" :useGrouping="false" id="cedula" :modelValue="frm_bancario.documento" placeholder="Número de documento" class="w-full" v-model="frm_bancario.documento" :class="{'p-invalid': submitted && !frm_bancario.documento, 'mb-3': true}"/>
								</div>
							</template>
						<!-- </template> -->
					</div>
				</template>
				
				
			</div>
			<template #footer>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modal_pagos = false"/>
				<template v-if="select_medio_pago != 0">
				<Button label="Atrás" icon="pi pi-arrow-left" class="p-button-text" @click="select_medio_pago = 0"/>
				</template>
				
				<template v-if="select_medio_pago == 1 && select_bank!=null">
				<Button label="Pagar" :loading="loader" icon="pi pi-wallet" class="p-button-raised" @click="pagar_factura"/>
				</template>
				<template v-if="select_medio_pago == 2">
				<Button label="Pagar" :loading="loader" icon="pi pi-wallet" class="p-button-raised" @click="pagar_factura"/>
				</template>
				<template v-if="select_medio_pago == 3">
				<Button label="Siguiente" :loading="loader" icon="pi pi-arrow-right" class="p-button-raised" @click="openmodalmporpay"/>
				</template>
			</template>
		</Dialog>
		<Dialog v-model:visible="deleteProductDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
			<div class="flex align-items-center justify-content-center">
				<i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
				<span>¿Estás seguro de que quieres eliminar esta factura entre las seleccionadas para pago <b></b>?</span><br>
			</div>
			<div class="flex align-items-center justify-content-center">
				<template v-if="loader==true">
					<i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
				</template>
			</div>
			<template #footer>
				<Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false"/>
				<Button id="buttoneliminar" label="Eliminar" icon="pi pi-check" class="p-button-text" @click="deleteFacturaSelected" />
			</template>
		</Dialog>
		<Dialog v-model:visible="modalimportpago" :style="{width: '600px'}" header="Confirmar" :modal="true">
			<div class="flex align-items-center justify-content-center">
				<FileUpload 
						name="demo[]" 
						v-model="comprobante" 
						@beforeUpload="onAdvancedUpload" 
						@clear="limpiarcomprobantes"
						:multiple="true" 
						:maxFileSize="1000000" 
						chooseLabel="Nuevo archivo"
						uploadLabel="Confirmar archivos y Enviar"
						cancelLabel="Cancelar archivos"
						>
						
						<!-- <FileUpload name="demo[]" url="https://www.primefaces.org/upload.php" @upload="onAdvancedUpload($event)" :multiple="true" accept="image/*" :maxFileSize="1000000"> -->
							
							<template #empty>
								<div class="flex align-items-center justify-content-center flex-column">
									<i class="pi pi-cloud-upload border-2 border-circle p-5 text-5xl text-400 border-400 mb-1" />
									<p>Arrastre y suelte los archivos aquí para cargarlos.</p>
								</div>
							</template>
						</FileUpload>
			</div>
			<div class="flex align-items-center justify-content-center">
				<template v-if="loader==true">
					<i class="pi pi-spin pi-spinner" style="font-size: 3rem"></i>
				</template>
			</div>
			<template #footer>
				<Button label="Cerrar" icon="pi pi-times" class="p-button-text" @click="modalimportpago = false"/>
			</template>
		</Dialog>
	</div>
</template>

<script>
// import axios from 'axios'
export default {
	data() {
		return {
			message: [],
			customers: [],
			list_banks: [],
			abonos: [],
			info_factura: [],
			frm_bancario: {
				card_fecha: null,
				documento: 0,
				bank: null,
				card_number: '',
				card_exp_year: '',
				card_exp_month: '',
				descripcion: '',
				valor_pagar: '',
				invoice: '',
				impuesto: 0,
				base_impuesto: 0,
				currency: "COP",
				selectipo_documento: null,
				name: '',
				card_cvc: '',
				country: 'CO',
				cell_phone: 0,
				prefijo: '',
				email: ''
			},
			detalle_cartera: [],
			select_parcial: [],
			facturas_seleccionadas: [],
			select_bank:null,
			table_abonos: null,
			table_facturas: null,
			pago_parcial_actualizado: false,
			pago_parcial: false,
			submitted: false,
			visibleRight: false,
			value18: 10.50,
			select_medio_pago:0,
			checkfacturas: [],
			username:null,
			comprobante:null,
			modalimportpago: false,
			loader: false,
			total_pagar: 0,
			modal_pagos: false,
			email:null,
			//0 a 30 dias
			cuantos_0a30: 0,
			cuantos_0a30_valor: 0,
			detalle_0a30: [],
			//31 a 60 dias
			cuantos_31a60: 0,
			cuantos_31a60_valor: 0,
			detalle_31a60: [],
			//61 a 90 dias
			cuantos_61a90: 0,
			cuantos_61a90_valor: 0,
			detalle_61a90: [],
			//más de 90 dias
			cuantos_mas_90: 0,
			cuantos_mas_90_valor: 0,
			detalle_mas_90: [],
			//sin vencer
			sin_vencer: 0,
			sin_vencer_valor: 0,
			detalle_sin_vencer: [],
			cartera: 0,
			centro_costos: [],
			valor_seleccionado_facturas: 0,
			select_proveedor: null,
			customers_group: [],
			customers_group_cartera: [],
			load_indicadores: false,
			deleteProductDialog: false,
			paginador_mas_90: {},
			paginador_61a90: {},
			paginador_31a60: {},
			paginador_0a30: {},
			pagination: {
				current_page: 0,
				from: 0,
				last_page: 0,
				per_page: 0,
				to: 0,
				total: 0
			},
			count: 0,
			valor_a_pagar: 0,
			search_paginador: false,
			selectedProducts: null,
			campo_selected: null,
			loading: [false, false, false],
			text_filter: '',
			frmfiltros: {
                fechasolicitud: '',
                rangofechasolicitud: false,
                fechasolicitudrango: '',
            },
			campos_tabla: [
				{name: 'Fecha Venta', code: 'fecha_venta'},
				{name: 'Fecha Vencimiento', code: 'fecha_vencimiento'},
				{name: 'Número Factura', code: 'num_factura'}
			],
			tipos_documentos: [
				{name: 'NIT', code: 'NIT'},
				{name: 'Cédula de ciudadanía', code: 'CC'},
				{name: 'Tarjeta de extranjería', code: 'TE'},
				{name: 'Cédula de extranjería', code: 'CE'},
				{name: 'Pasaporte', code: 'PAS'},
			],
			items: [
					{label: 'Update', icon: 'pi pi-fw pi-refresh'},
					{label: 'Edit', icon: 'pi pi-fw pi-pencil'}
				],
			ingresoCostumer: false
		}
	},
	mounted(){
		this.cargar_empresas();
	},
	methods:{
		convert_saldo(data){
			return parseInt(data);
		},
		valor_facturas(event) {
			if(this.checkfacturas[event.index]==true){
				this.checkfacturas[event.index] = false;
			}else{
				this.checkfacturas[event.index] = true;
			}
			
			if (this.selectedProducts) {
				var total_pagar = 0;
				this.selectedProducts.forEach(element => {
					total_pagar = parseInt(total_pagar)+parseInt(element.valor_a_pagar);
				});
				this.valor_seleccionado_facturas = new Intl.NumberFormat().format(total_pagar);
			}else{
				this.valor_seleccionado_facturas = 0;
			}
			
		},
		show_parcial(data,index){
			console.log(this.select_parcial);
			console.log(data);
			console.log(index);
		},
		showvalor(data,indicador){
			console.log(data);
			// console.log('Selecciono:  '+data.documento_generador+'  '+data.document);
			this.customers_group.forEach(element => {
				// console.log(element.document);
				// console.log(element.documento+'  '+element.document);
				if (element.document == data.document && element.documento == data.documento_generador) {
				// 	console.log(element.document);
					this.select_proveedor = element;
					//0 a 30 dias
					this.cuantos_0a30 = data.indicadores.modulo_0a30.total;
					this.cuantos_0a30_valor = new Intl.NumberFormat().format(data.indicadores.modulo_0a30.total_valor);

					//31 a 60 dias
					this.cuantos_31a60 = data.indicadores.modulo_31a60.total;
					this.cuantos_31a60_valor = new Intl.NumberFormat().format(data.indicadores.modulo_31a60.total_valor);

					//61 a 90 dias
					this.cuantos_61a90 = data.indicadores.modulo_61a90.total;
					this.cuantos_61a90_valor = new Intl.NumberFormat().format(data.indicadores.modulo_61a90.total_valor);

					//más de 90 dias
					this.cuantos_mas_90 = data.indicadores.modulo_mayor_90.total;
					this.cuantos_mas_90_valor = new Intl.NumberFormat().format(data.indicadores.modulo_mayor_90.total_valor);

					//sin vencer
					this.sin_vencer = data.indicadores.modulo_sin_vencer.total;
					this.sin_vencer_valor = new Intl.NumberFormat().format(data.indicadores.modulo_sin_vencer.total_valor);
				}
			});
			//  = data;
			this.selectindicador(indicador);
			// selectindicador(indicador)
		},
		valor_facturas_todos(event) {
			for (let index = 0; index < event.data.length; index++) {
				this.checkfacturas[index] = true;
			}

				// console.log(this.selectedProducts);
				var total_pagar = 0;
				event.data.forEach(element => {
					total_pagar = parseInt(total_pagar)+parseInt(element.valor_a_pagar);
					// console.log(total_pagar);
				});
				this.valor_seleccionado_facturas = new Intl.NumberFormat().format(total_pagar);

		},
		quitar_valor_facturas_todos() {
			this.checkfacturas = [];
			this.valor_seleccionado_facturas = 0;
		},
		selected_factur(data) {
				console.log(this.checkfacturas);
				console.log(data);
			
		},
		show_nuevo_valor(valor,index,data) {
				// console.log(this.checkfacturas);
				// console.log(valor);
				// console.log(index);
				console.log(data.saldo);
				console.log(data.saldo);
				var total = String(valor).replaceAll('$', '').replaceAll(',00', '').replaceAll('.', '');
				
			if (this.facturas_seleccionadas) {
				this.pago_parcial_actualizado = false;
				var total_pagar = 0;
				var contador = 0;
				this.facturas_seleccionadas.forEach(element => {
					
					// console.log('contador: '+contador);
					// console.log('index: '+index);
					if(contador==index){
						// console.log("Son iguales");
						// valor = valor.replaceAll(',', '');
						// valor = valor.replaceAll('$', '');
						// valor = valor.replaceAll(' ', '');
						// console.log('valor: '+String(valor));
						// console.log('total: '+total);
						// console.log('total int: '+parseInt(total));
						element.saldo = parseInt(total);
						total_pagar = parseInt(total_pagar)+parseInt(total);
					}else{
						total_pagar = parseInt(total_pagar)+parseInt(element.saldo);
					}
					console.log(total_pagar);
					contador++;
				});
				this.valor_seleccionado_facturas = new Intl.NumberFormat().format(total_pagar);
			}else{
				this.valor_seleccionado_facturas = 0;
			}
			console.log(this.valor_seleccionado_facturas);
			
		},
		openmodalmporpay(){
			if (this.select_medio_pago == 3 && (
			this.frm_bancario.selectipo_documento==null ||
			!this.frm_bancario.documento ||
			!this.frm_bancario.email ||
			!this.frm_bancario.descripcion ||
			!this.frm_bancario.cell_phone ||
			!this.frm_bancario.name))
			{
				this.submitted = true;
				this.$toast.add({severity:'error', summary: "Error", detail:"Por favor llene todos los campos", life: 4000});
				return;
			}
			this.modalimportpago = true;
		},
		refreshSaldo(){
			if (this.facturas_seleccionadas) {
				var total_pagar = 0;
				this.facturas_seleccionadas.forEach(element => {
						total_pagar = parseInt(total_pagar)+parseInt(element.saldo);
						// console.log(element);
				});
				this.valor_seleccionado_facturas = new Intl.NumberFormat().format(total_pagar);
			}else{
				this.valor_seleccionado_facturas = 0;
			}
			this.pago_parcial_actualizado = true;
			this.$toast.add({severity:'success', summary: "Saldo Refrescado", detail:'', life: 4000});
		},
		
		open_modal_pagar(){
			// console.log(data);
			var data = this.facturas_seleccionadas[0];
			var _that = this;
			this.loader = true;
			this.abonos = [];
			this.frm_bancario.card_fecha = null;
			this.frm_bancario.card_number = '';
			this.frm_bancario.card_exp_year = '';
			this.frm_bancario.card_exp_month = '';
			this.frm_bancario.card_cvc = '';
			this.frm_bancario.name = '';
			this.frm_bancario.email = '';
			this.frm_bancario.cell_phone = 0;
			this.frm_bancario.documento = 0;
			this.frm_bancario.descripcion = '';

			this.frm_bancario.valor_pagar = 0;
			this.select_medio_pago = 0;
			this.select_bank = null;
			var url = 'listar_bancos';
			window.api.call('post', url, {facturas_seleccionadas: this.facturas_seleccionadas,nit_customer: this.select_proveedor.document}).then( function(response) {
				if(response.status == 200){
					_that.loader = false;
					_that.modal_pagos = true;
					_that.frm_bancario.name = data.generador_nombre;
					_that.frm_bancario.documento = parseInt(data.generador_documento);
					_that.frm_bancario.valor_pagar = _that.valor_seleccionado_facturas;
					_that.list_banks = response.data.bancos.data;
					_that.abonos = response.data.abonos;
					_that.info_factura = data;
				}
			}).catch(function (err) {
				_that.loader = false;
				console.log(err);
				

			});
		},
		open_detalle_select_facturas(){
			this.visibleRight = true;
			// console.log("Volvio a cargarrrr");
			this.facturas_seleccionadas = this.selectedProducts;
			if (this.facturas_seleccionadas &&  this.selectedProducts) {
				this.facturas_seleccionadas.forEach(element => {
					var total_pagar = parseInt(element.venta_total)-parseInt(element.venta_abonos);
					element.saldo = total_pagar;
					// element.saldo = new Intl.NumberFormat().format(total_pagar);
				});
			}
			this.pago_parcial_actualizado = true;
			
		},
		onAdvancedUpload(event) {
			this.loader = true;
			var _that = this;
			var formData = new FormData();
			formData.append('import_file', event);
			formData.append('frm_bancario', JSON.stringify(this.frm_bancario));
			formData.append('nit_customer', this.select_proveedor.document);
			formData.append('facturas_seleccionadas', JSON.stringify(this.facturas_seleccionadas));
			var url = 'adjuntar_comprobante';
			window.api.call('post', url, formData).then( function(response) {
				if(response.status == 200){
					_that.loader = false;
					_that.$toast.add({ severity: 'success', summary: 'Se Envio tu comprobante', detail: 'Pendiente de confirmación', life: 6000 });
					_that.modalimportpago = false;
					// _that.modal_pagos = true;
					// _that.frm_bancario.name = data.generador_nombre;
					// _that.frm_bancario.documento = parseInt(data.generador_documento);
					// _that.frm_bancario.valor_pagar = _that.valor_seleccionado_facturas;
					// _that.list_banks = response.data.bancos.data;
					// _that.abonos = response.data.abonos;
					// _that.info_factura = data;
				}
			}).catch(function (err) {
				_that.loader = false;
				
				console.log(err);
				

			});
        },
		limpiarcomprobantes() {
            this.$toast.add({ severity: 'info', summary: 'Success', detail: 'Limpiado con exito', life: 3000 });
			
			// console.log(event);
			this.comprobante = null;
			console.log(this.comprobante);
        },
		confirmDeleteProduct(data,index){
			// return parseInt(data);
			console.log(data);
			console.log(index);
			localStorage.setItem("idx_factura_elimiada",index);
			this.deleteProductDialog = true;

		},
		deleteFacturaSelected(){
			const check = [];
			check.index = localStorage.getItem("idx_factura_elimiada")
			console.log(check);
			this.facturas_seleccionadas.splice(localStorage.getItem("idx_factura_elimiada"),1);
			this.valor_facturas(check);
			if (this.facturas_seleccionadas) {
				var total_pagar = 0;
				this.facturas_seleccionadas.forEach(element => {
					total_pagar = parseInt(total_pagar)+parseInt(element.saldo);
				});
				this.valor_seleccionado_facturas = new Intl.NumberFormat().format(total_pagar);
			}else{
				this.valor_seleccionado_facturas = 0;

			}
			console.log(this.valor_seleccionado_facturas);
			this.$toast.add({severity:'success', summary: "Se quito la factura con exito", detail:"", life: 4000});
			this.deleteProductDialog = false;
		},
		valor_cosolidado_facturas(){
			this.valor_a_pagar = 0;
			if (this.selectedProducts) {
				this.selectedProducts.forEach(element => {
				// valor_total = valor_total+element.venta_saldo;
				this.valor_a_pagar = parseInt(this.valor_a_pagar)+parseInt(element.valor_a_pagar);
			});
			this.valor_a_pagar = new Intl.NumberFormat().format(this.valor_a_pagar);
			}
			
			return this.valor_a_pagar;
			// return new Intl.NumberFormat().format(valor_a_pagar);
		},
		calcular_saldo(valor_factura, abono){
			var total_pagar = parseInt(valor_factura)-parseInt(abono);
			return new Intl.NumberFormat().format(total_pagar);
		},
		pagar_factura(){
			console.log(this.select_bank);

			// this.$swal('Hello Vue world!!!');
			if (this.frm_bancario.valor_pagar==0 || !this.frm_bancario.valor_pagar) {
				this.$toast.add({severity:'error', summary: "Error", detail:"Ingrese un Valor a Pagar", life: 4000});
				return;
			}
			if (this.select_medio_pago ==1 && this.select_bank==null||this.select_bank==0) {
				this.$toast.add({severity:'error', summary: "Error", detail:"Seleccione un banco", life: 4000});
				return;
			}else{
				this.frm_bancario.bank = this.select_bank;
			}
			// if (this.frm_bancario.selectipo_documento==null) {
			// 	this.$toast.add({severity:'error', summary: "Error", detail:"Seleccione un tipo documento", life: 4000});
			// 	return;
			// }
			if (this.select_medio_pago == 1 && (
				this.frm_bancario.selectipo_documento==null ||
				!this.frm_bancario.documento ||
				!this.frm_bancario.email ||
				!this.frm_bancario.cell_phone ||
				!this.frm_bancario.name
				)) {
				this.submitted = true;
				this.$toast.add({severity:'error', summary: "Error", detail:"Por favor llene todos los campos", life: 4000});
				return;
			}
			if (this.select_medio_pago == 2 && (
			this.frm_bancario.selectipo_documento==null ||
			!this.frm_bancario.card_number ||
			!this.frm_bancario.card_fecha ||
			!this.frm_bancario.card_cvc ||
			!this.frm_bancario.documento ||
			!this.frm_bancario.email ||
			!this.frm_bancario.descripcion ||
			!this.frm_bancario.cell_phone ||
			!this.frm_bancario.name))
			{
				this.submitted = true;
				this.$toast.add({severity:'error', summary: "Error", detail:"Por favor llene todos los campos", life: 4000});
				return;
			}
			console.log(this.comprobante);
			// if (this.select_medio_pago == 3 && (
			// this.frm_bancario.selectipo_documento==null ||
			// !this.frm_bancario.documento ||
			// !this.frm_bancario.email ||
			// !this.frm_bancario.descripcion ||
			// !this.frm_bancario.cell_phone ||
			// !this.frm_bancario.name ||
			// ))
			// {
			// 	this.submitted = true;
			// 	this.$toast.add({severity:'error', summary: "Error", detail:"Por favor llene todos los campos", life: 4000});
			// 	return;
			// }
			this.frm_bancario.invoice = this.info_factura.venta_numero;
			this.frm_bancario.prefijo = this.info_factura.venta_prefijo;
			this.frm_bancario.valor_pagar = this.frm_bancario.valor_pagar.replaceAll('.', '');
			this.frm_bancario.select_medio_pago = this.select_medio_pago;
			var _that = this;
			this.loader = true;
			var url = 'pagar_factura';
			window.api.call('post', url, {frm_bancario: this.frm_bancario,nit_customer: this.select_proveedor.document,facturas_seleccionadas: this.facturas_seleccionadas}).then( function(response) {
				if(response.status == 200){
					_that.loader = false;
					if (response.data.pse) {
						if (response.data.pse.success == false) {
							_that.$toast.add({severity:'error', summary: response.data.pse.title_response, detail:response.data.pse.text_response, life: 4000});
							response.data.pse.data.errores.forEach(element => {
								_that.$toast.add({severity:'error', summary: response.data.pse.title_response, detail:element.errorMessage, life: 8000});
							});
						}else{
							_that.$toast.add({severity:'success', summary: response.data.pse.title_response, detail:response.data.pse.text_response, life: 4000});
							_that.modal_pagos = false;
							window.open(response.data.pse.data.urlbanco, '_blank');
						}
					}
					if (response.data.pay) {
						if (response.data.pay.status == false) {
							_that.$toast.add({severity:'warn', summary: 'Error de conexión', detail: 'Tuvimos un breve problema de conexión, intenta nuevamente', life: 6000});
						}else{
							if (response.data.pay.data.estado=="Rechazada") {
								_that.$toast.add({severity:'warn', summary: 'Error', detail: response.data.pay.data.respuesta, life: 6000});
							}
							_that.$toast.add({severity:'success', summary: 'Pago exitoso!', detail:'El pago fue aceptado con exito', life: 8000});
							_that.modal_pagos = false;
						}
					}
					
					_that.visibleRight = false;
					// _that.frm_bancario.name = data.generador_nombre;
					// _that.frm_bancario.documento = data.generador_documento;
					// _that.list_banks = response.data.bancos.data;
					// _that.abonos = response.data.abonos;
					// _that.info_factura = data;
				}
			}).catch(function (err) {
				console.log(err);
				if (err.data){
					if (err.data.response) {
						if (err.data.response.errors) {
							err.data.response.errors.forEach(element => {
								_that.$toast.add({severity:'warn', summary: 'Error en el pago', detail: element.errorMessage, life: 6000});
								console.log(element);
							});
						}
					}
					// _that.$toast.add({severity:'warn', summary: 'Error de conexión', detail: 'Tuvimos un breve problema de conexión, intenta nuevamente', life: 6000});

				}
					_that.loader = false;
					
			});
			
		},
		bank_selected(){
			console.log(this.select_bank);
			// window.open('https://www.linkedin.com/feed/', '_blank');
		},
		tipo_formato_fecha_card(){
			// const formato_fecha = null;
			// if (this.frm_bancario.card_fecha) {
			// 	// this.frm_bancario.card_fecha.toLocaleDateString("en-US", { year: 'numeric', month: 'numeric' });
			// 	// (this.frm_bancario.card_fecha.getUTCMonth()+1)+'/'+this.frm_bancario.card_fecha.getUTCFullYear()
			// 	formato_fecha = this.frm_bancario.card_fecha.getUTCMonth() ?? null;
			// 	console.log(formato_fecha);
			// }
			
			// return formato_fecha;

			return 'hola';
		},
		cargar_empresas(){
			const cookieName = 'Email';
			const IdRol = 'IdRol';
			this.loader = true;
			this.customers = [];
			let cookie = {};
			document.cookie.split(';').forEach(function(el) {
			let [key,value] = el.split('=');
			cookie[key.trim()] = value;
			})
			this.detalle_cartera = [];
			var email = cookie[cookieName];
			var id_rol = cookie[IdRol];
			var _that = this;
			this.customers_group = [];
			this.customers_group_cartera = [];
			this.load_indicadores = true;
			var url = 'cargar_empresas';
			window.api.call('post', url, {email: email,id_rol: id_rol}).then( function(response) {
				if(response.status == 200){
					_that.loader = false;
					_that.load_indicadores = false;
					_that.customers_group_cartera = response.data.customer_totales;
					_that.customers_group = response.data.empresas;
					if (response.data.empresas.length == 1) {
						_that.customers_group = response.data.empresas;
						
						_that.select_proveedor = response.data.empresas[0];
						_that.customer_selected();
					}else{
						// _that.loader = false;
						_that.customers_group = response.data.empresas;
						// _that.loading[0] = false;
					}
				}
			}).catch(function (err) {
				console.log(err);
				_that.loader = false;
				_that.load_indicadores = false;
			});
		},
		cargar_indicadores(){
			this.loader = true;
			this.detalle_cartera = [];
			this.detalle_0a30 = [];
			this.cuantos_0a30 = 0;
			this.cuantos_0a30_valor = 0;
			this.detalle_31a60 = [];
			this.cuantos_31a60 = 0;
			this.cuantos_31a60_valor = 0;
			this.detalle_61a90 = [];
			this.cuantos_61a90 = 0;
			this.cuantos_61a90_valor = 0;
			this.detalle_mas_90 = [];
			this.cuantos_mas_90 = 0;
			this.cuantos_mas_90_valor = 0;
			this.valor_seleccionado_facturas = 0;
			this.facturas_seleccionadas = [];
			this.selectedProducts = [];
			this.paginador_0a30 = {};
			this.paginador_31a60 = {};
			this.paginador_61a90 = {};
			this.paginador_mas_90 = {};
			this.detalle_sin_vencer = [];
			
			this.load_indicadores = true;
			this.sin_vencer = 0;
			this.sin_vencer_valor = 0;
			var _that = this;
			var url = 'cargar_pendientes';
			window.api.call('post', url, {nit_generador: this.select_proveedor.documento,nit_customer: this.select_proveedor.document}).then( function(response) {
				if(response.status == 200){
					_that.loader = false;
					_that.load_indicadores = false;

					//0 a 30 dias
					_that.cuantos_0a30 = response.data.data.modulo_0a30.total;
					_that.cuantos_0a30_valor = new Intl.NumberFormat().format(response.data.data.modulo_0a30.total_valor);

					//31 a 60 dias
					_that.cuantos_31a60 = response.data.data.modulo_31a60.total;
					_that.cuantos_31a60_valor = new Intl.NumberFormat().format(response.data.data.modulo_31a60.total_valor);

					//61 a 90 dias
					_that.cuantos_61a90 = response.data.data.modulo_61a90.total;
					_that.cuantos_61a90_valor = new Intl.NumberFormat().format(response.data.data.modulo_61a90.total_valor);

					//más de 90 dias
					_that.cuantos_mas_90 = response.data.data.modulo_mayor_90.total;
					_that.cuantos_mas_90_valor = new Intl.NumberFormat().format(response.data.data.modulo_mayor_90.total_valor);

					//sin vencer
					_that.sin_vencer = response.data.data.modulo_sin_vencer.total;
					_that.sin_vencer_valor = new Intl.NumberFormat().format(response.data.data.modulo_sin_vencer.total_valor);
					// console.log(response.data.empresas);


					// _that.customers_group = response.data.empresas;
				}
			}).catch(function (err) {
				console.log(err);
					_that.loader = false;
					_that.load_indicadores = false;

			});
		},
		selectindicador(indicador){
			// this.cleartable();
			switch (indicador) {
				case 1:
						this.cartera = 1;
					break;
				case 2:
						this.cartera = 2;
					break;
				case 3:
						this.cartera = 3;
					break;
				case 4:
						this.cartera = 4;
					break;
				case 5:
						this.cartera = 5;
					break;
				default:
					break;
			}
			this.select_estado = null;
			this.campo_selected = null;
			this.frmfiltros.rangofechasolicitud = '';
			this.frmfiltros.fechasolicitudrango = '';
			this.search_paginador = false;
			const page = {};
			page.page = 0;
			this.onChangePage(page);
		},
		customer_selected(){
			// console.log(this.select_proveedor);
			if (this.select_proveedor!=null) {
				this.valor_seleccionado_facturas = 0
				this.abonos = [];
				this.cargar_indicadores();
				// this.load_indicadores = true;
			}else{

			}
		},
		cleartable(){
			this.detalle_cartera = [];
			this.pagination.current_page = 0;
				this.pagination.from= 0;
				this.pagination.last_page= 0;
				this.pagination.per_page= 0;
				this.pagination.to= 0;
				this.pagination.total= 0;
				this.valor_seleccionado_facturas = 0;
			this.facturas_seleccionadas = [];
			this.selectedProducts = [];
			// 	this.select_estado = null;
			// this.campo_selected = null;
			// this.frmfiltros.rangofechasolicitud = '';
			// this.frmfiltros.fechasolicitudrango = '';
			// this.text_filter = '';
		},
		limpiar(){
			this.select_estado = null;
			this.campo_selected = null;
			this.frmfiltros.rangofechasolicitud = '';
			this.frmfiltros.fechasolicitudrango = '';
			this.text_filter = '';
			const cookieName = 'Email';
				const IdRol = 'IdRol';
				this.loader = true;
				this.customers = [];
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				})
				var email = cookie[cookieName];
				var id_rol = cookie[IdRol];
			// this.loadDataTable(id_customer);
			// this.clearPaginator();
			// this.loadDataTable1(1,id_customer);
			this.loadDataTable1((0+1),this.cartera,email,id_rol);
		},
		onChangePage(page) {
			// this.cleartable();
				const cookieName = 'Email';
				const IdRol = 'IdRol';
				this.loader = true;
				this.customers = [];
				let cookie = {};
				document.cookie.split(';').forEach(function(el) {
				let [key,value] = el.split('=');
				cookie[key.trim()] = value;
				})
				var email = cookie[cookieName];
				var id_rol = cookie[IdRol];
				
				
				if(this.search_paginador == false){
					this.loadDataTable1((page.page+1),this.cartera,email,id_rol);
				}else{
					this.load(0,page.page+1);
				}
		},
		loadDataTable1(page,cartera,email,id_rol){
			// this.cleartable();
			this.detalle_cartera = [];
			this.pagination.current_page = 0;
			this.pagination.from= 0;
			this.pagination.last_page= 0;
			this.pagination.per_page= 0;
			this.pagination.to= 0;
			this.pagination.total= 0;
			this.loader = true;
			var _that = this;
                window.api.call('post', '/cargar_cartera?&page='+page, {cartera: cartera,nit_generador: this.select_proveedor.documento,nit_customer: this.select_proveedor.document}).then( function(response) {
                    if(response.status == 200){
						_that.detalle_cartera = response.data.data.data;
						_that.pagination = response.data.data;
						
						_that.loader = false;
						_that.search_paginador = false;
                    }
                }).catch(function (err) {
                    console.log(err);
					_that.loader = false;
                });
		},
		salirCustomer(){
			this.ingresoCostumer = false;
		},
		load(index,page) {
			if(this.campo_selected==null){
				this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor seleccionar un campo e ingresar texto a filtrar', life: 4000});
			}else{
				if(this.campo_selected=='fecha_venta' && this.text_filter==''){
					this.$toast.add({severity:'error', summary: 'Falta fecha', detail:'Favor seleccionar una fecha a filtrar', life: 4000});

				}else{
					if(this.frmfiltros.rangofechasolicitud==true && this.frmfiltros.fechasolicitudrango==''){
						this.$toast.add({severity:'error', summary: 'Falta información', detail:'Favor seleccionar una fecha inicio y una fecha fin a filtrar', life: 4000});
					}else{
						this.loading[index] = true;
						// this.cleartable();
						this.detalle_cartera = [];
						this.pagination.current_page = 0;
						this.pagination.from= 0;
						this.pagination.last_page= 0;
						this.pagination.per_page= 0;
						this.pagination.to= 0;
						this.pagination.total= 0;
						this.loader = true;
						var _that = this;
						let text = '';
						text = this.text_filter;
							window.api.call('post', '/filtrar_cartera_generador?&page='+page, {cartera: this.cartera,nit_generador: this.select_proveedor.documento,nit_customer: this.select_proveedor.document,nom_campo: this.campo_selected,text: text,rango: this.frmfiltros.rangofechasolicitud,fecha_fin: this.frmfiltros.fechasolicitudrango}).then( function(response) {
								if(response.status == 200){
									_that.detalle_cartera = response.data.data.data;
									_that.pagination = response.data.data;
									
									// console.log(response.data.data);
									_that.loader = false;
									_that.loading[index] = false;
									_that.search_paginador = true;
								}
							}).catch(function (err) {
								console.log(err);
								_that.loader = false;
								_that.loading[index] = false;
							});
					}
				}
				
			}
		},
	},
	computed: {
			isRTL() {
				return this.$appState.RTL;
			}
		}
}
</script>

<style scoped>
.modal-pago{
	z-index: 1000;
}
.valor_facturas{
	color: #EB7D10;
	font-size: 2.5rem;
	font-weight: 800;
}
.p-dialog-content{
	z-index: 1000;
}
.p-dialog{
	z-index: 1000;
}
.container-precios{
	/* padding: 1; */
    /* border: 1px solid #EB7D10; */
    border-radius: 15px;
    height: 100%;
}
.container-precios:hover{
    transition: 0.5s;
    -webkit-box-shadow: 0px 0px 42px 4px rgba(235,125,16,1);
    -moz-box-shadow: 0px 0px 42px 4px rgba(235,125,16,1);
    box-shadow: 0px 0px 42px 4px rgba(235,125,16,1);
}
</style>
